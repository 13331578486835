<template>
    <div class="breadcrumbs-wrap">
        <ul
            class="breadcrumb"
            :class="{'breadcrumb-form': formPage}"
        >
            <li
                v-for="(item,i) in items"
                :key="i"
            >
                <router-link
                    :to="item.href"
                    class="breadcrumb-item"
                    :class="{'breadcrumb-item_active': item.href !== '#' && !formPage}"
                >
                    {{ $t(item.text) }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => []
        },
        formPage: {
            type: Boolean,
            default:  () => false
        }
    }
}
</script>

<style lang="scss" scoped>
.md-theme-default a:not(.md-button):hover {
    color: unset;
}

.breadcrumbs-wrap {
    margin-bottom: 20px;
    @media (max-width: 1024px) {
        position: absolute;
        top: 80px;
        left: 40px;
    }
}

ul.breadcrumb {
    padding: 10px 16px;
    list-style: none;
}

ul.breadcrumb li {
    display: inline;
    font-size: 16px;
    line-height: 24px;
}

ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
}

ul.breadcrumb-form li+li:before {
    padding: 8px;
    color: black;
    content: ">\00a0";
}

a.breadcrumb-item {
    color: #000000;
    text-decoration: none;
    cursor: default;
}

.breadcrumb-item_active {
    color: #0045FF!important;
    cursor: pointer!important;
}
</style>