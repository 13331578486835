<template>
  <div
      class="notify-existing-user-wrapper"
      :class="{'hasApplication_wrapper': application}"
  >
    <div class="notify-existing-user">
      <div v-if="!application" class="close">
        <img
            src="@/assets/images/close-icon.svg"
            alt="close"
            @click="$emit('close')"
        />
      </div>

      <p class="text">{{$t('message')}}</p>
      <p class="text">{{$t('message1')}}</p>

      <button class="btn-login" @click="$emit('cancel')">Ok</button>

    </div>
  </div>
</template>

<script>

export default {
  name: 'cancel-modal',
  props: {
    application: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
a:not(.md-button):hover {
  text-decoration: none;
}
.md-theme-default a:not(.md-button) {
  color: #FFFFFF;
}
.notify-existing-user-wrapper{
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  .notify-existing-user {
    width: 500px;
    background: #FFFFFF;
    padding: 30px 30px 50px 50px;
  }

  .close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    img {
      cursor: pointer;
    }
  }

  .text {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .btn-login {
    display: block;
    margin: 0 auto;
    background-color: #0045FF;
    color: #FFFFFF;
    padding: 13px 32px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    outline: none;
    border: none;
    cursor: pointer;
  }
}
.hasApplication_wrapper {
  background: rgba(0, 0, 0, 0.8);
}
</style>