<template>
    <div class="applications-form_wrap" :key="index">
        <Breadcrumbs
            :items="items"
            :formPage="true"
        />
        <h2 class="applications-form_header">{{ $t('profile') }}</h2>
        <div class="form-status_wrap">
            <form action="" @submit.prevent="save">
                <div v-if="scholarshipTypeHandler('Resume')">
                    <div class="form-field">
                        <p>{{ $t('resume') }} <span v-if="requiredHandler('Resume')">*</span></p>
                        <md-field :class="{'uploaded': form2.resume}">
                            <md-file
                                @change="changeHandler('resume', 'resumeStatusId')"
                                :placeholder="$t('resume')"
                                :required="requiredFile('Resume', 'resume')"
                                :value="files.resume"
                                accept="application/pdf"
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div v-html="svgApproved('resumeStatusId')"></div>
                            <div v-html="svgDeclined('resumeStatusId')"></div>
                        </div>
                    </div>
                    <div v-if="filesError.resume" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{fileErrorMsg}}</span>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('Resume')" class="form-field">
                    <p>{{ $t('resumeComment') }}</p>
                    <md-field>
                        <md-input v-model="form2.resumecomment" :placeholder="$t('resumeComment')"/>
                    </md-field>
                </div>
                <div class="form-field">
                    <p></p>
                    <span class="block-title">{{ $t('lastDegree') }}</span>
                </div>
                <div v-if="scholarshipTypeHandler('Lastdegreedateofgraduation')" class="form-field">
                  <p>{{ $t('LastDegreeDateOfGraduation') }} <span v-if="requiredHandler('Lastdegreedateofgraduation')">*</span></p>
                    <md-datepicker  v-model="form2.lastdegreedateofgraduation" class="graduationDate" :required="requiredFile('Lastdegreedateofgraduation', 'Lastdegreedateofgraduation')" />
                    <div v-if="showLastDegreeGraduationTooltip" class="tooltip-wrap">
                        <img src="@/assets/images/info.svg" alt="info" @click="lastDegreeGraduationTooltipActive = !lastDegreeGraduationTooltipActive" />
                        <md-tooltip :md-active.sync="lastDegreeGraduationTooltipActive" md-direction="right">
                            <p v-html="lastDegreeGraduationTooltipMsg"></p>
                        </md-tooltip>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('Lastdegreedateofgraduation') && parseInt($route.params.id) === 1" class="form-field">
                    <p></p>
                    <span class="block-title">{{seniorOrJunior}}</span>
                </div>
                <div v-if="scholarshipTypeHandler('LastdegreelevelId')" class="form-field">
                    <p>{{ $t('lastDegreeLevel') }} <span v-if="requiredHandler('LastdegreelevelId')">*</span></p>
                    <select name="" class="select-field" v-model="form2.lastdegreelevelid" :required="requiredHandler('LastdegreelevelId')">
                        <option
                            v-for="(level,i) in lastDegreeLevels"
                            :selected="form2.lastdegreelevelid === level.id"
                            :key="i"
                            :value="level.id"
                        >
                            {{ level.name }}
                        </option>
                    </select>
                </div>
                <div v-if="scholarshipTypeHandler('LastdegreefieldId')" class="form-field">
                    <p>{{ $t('lastDegreeField') }} <span v-if="requiredHandler('LastdegreefieldId')">*</span></p>
                    <select name="" class="select-field" v-model="form2.lastdegreefieldid" :required="requiredHandler('LastdegreefieldId')">
                        <option
                            v-for="(field,i) in lastDegreeFields"
                            :selected="form2.lastdegreefieldid === field.id"
                            :key="i"
                            :value="field.id"
                        >
                            {{ field.name }}
                        </option>
                    </select>
                </div>
                <div v-if="scholarshipTypeHandler('Lastdegreespeciality')" class="form-field">
                    <p>{{ $t('lastDegreeSpeciality') }} <span v-if="requiredHandler('Lastdegreespeciality')">*</span></p>
                    <md-field>
                        <md-input
                            v-model="form2.lastdegreespeciality"
                            :placeholder="$t('lastDegreeSpeciality')"
                            :required="requiredHandler('Lastdegreespeciality')"
                        ></md-input>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('LastdegreeinstitutioncityId')" class="form-field">
                    <p>Ville de l'établissement du dernier diplôme obtenu <span v-if="requiredHandler('LastdegreeinstitutioncityId')">*</span></p>
                    <v-select
                        :options="lastDegreeInstitutionCities"
                        label="name"
                        value="id"
                        v-model="form2.lastdegreeinstitutioncityid"
                        class="select-field custom-select"
                    ></v-select>
                </div>
                <div v-if="form2.lastdegreeinstitutioncityid && form2.lastdegreeinstitutioncityid.name === 'Autre'" class="form-field">
                    <p>{{ $t('Lastdegreeinstitut') }} *</p>
                    <md-field>
                        <md-input
                            v-model="form2.lastdegreeinstitution"
                            :placeholder="$t('Lastdegreeinstitut')"
                            :required="requiredHandler('Lastdegreespeciality')"
                        ></md-input>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('Lastdegreeinstitution')" class="form-field">
                    <p>{{ $t('lastDegreeInstitution') }} <span v-if="requiredHandler('Lastdegreeinstitution')">*</span></p>
                    <select name="" class="select-field" v-model="form2.lastdegreeinstitutionid" :required="requiredHandler('Lastdegreeinstitution')">
                        <option
                            v-for="(field,i) in lastDegreeInstitution"
                            :selected="form2.lastdegreeinstitutionid === field.id"
                            :key="i"
                            :value="field.id"
                        >
                            {{ field.name }}
                        </option>
                    </select>
                </div>
                    <div v-for="(field,i) in lastDegreeInstitution" :key="i">
                        <div v-if="form2.lastdegreeinstitutionid === field.id && field.name === 'Autre'" class="form-field">
                            <p>{{ $t('Lastdegreeinstitut1') }} *</p>
                            <md-field>
                                <md-input
                                    v-model="form2.lastdegreeinstitutioncity"
                                    :placeholder="$t('Lastdegreeinstitut1')"
                                    :required="requiredHandler('Lastdegreespeciality')"
                                ></md-input>
                            </md-field>
                        </div>
                    </div>
                <div v-if="scholarshipTypeHandler('Lastdegreered')" class="form-field">
                    <p>{{ $t('lastDegreeRed') }} <span v-if="requiredHandler('Lastdegreered')">*</span></p>
                    <div class="radio-wrap">
                        <md-radio
                            v-for="item in lastDegreeRedList"
                            :key="item.id"
                            v-model="form2.lastdegreeredid"
                            :value="item.id"
                            class="md-primary"
                        >
                            {{ item.name }}
                        </md-radio>
                    </div>
                    <div v-if="isMaster" class="tooltip-wrap">
                        <img src="@/assets/images/info.svg" alt="info" @click="lastDegreeRedTooltipActive = !lastDegreeRedTooltipActive" />
                        <md-tooltip :md-active.sync="lastDegreeRedTooltipActive" md-direction="right">
                            <p v-html="lastDegreeRedTooltipMsg"></p>
                        </md-tooltip>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('Lastdegreecertifiedcopy')">
                    <div class="form-field">
                        <p>{{ $t('lastDegreeCertifiedCopy') }} <span v-if="requiredHandler('Lastdegreecertifiedcopy')">*</span></p>
                        <md-field :class="{'uploaded': form2.lastdegreecertifiedcopy}">
                            <md-file
                                @change="changeHandler('lastdegreecertifiedcopy', 'lastdegreecertifiedcopyStatusid')"
                                :placeholder="$t('lastDegreeCertifiedCopy')"
                                :required="requiredFile('Lastdegreecertifiedcopy', 'lastdegreecertifiedcopy')"
                                :value="files.lastdegreecertifiedcopy"
                                accept="application/pdf"
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div v-html="svgApproved('lastdegreecertifiedcopyStatusid')"></div>
                            <div v-html="svgDeclined('lastdegreecertifiedcopyStatusid')"></div>
                        </div>
                    </div>
                    <div v-if="filesError.lastdegreecertifiedcopy" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{fileErrorMsg}}</span>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('Lastdegreecertifiedcopy')" class="form-field">
                    <p>{{ $t('lastDegreeCertifiedCopyComment') }}</p>
                    <md-field>
                        <md-input v-model="form2.lastdegreecertifiedcopycomment" :placeholder="$t('lastDegreeCertifiedCopyComment')" />
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('Publicationlist')">
                    <div class="form-field">
                        <p>{{ $t('publicationList') }} <span v-if="requiredHandler('Publicationlist')">*</span></p>
                        <md-field :class="{'uploaded': form2.publicationlist}">
                            <md-file
                                @change="changeHandler('publicationlist', 'publicationlistStapublicationlistStatusidtusId')"
                                :placeholder="$t('publicationList')"
                                :required="requiredFile('Publicationlist', 'publicationlist')"
                                :value="files.publicationlist"
                                accept="application/pdf"
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div v-html="svgApproved('publicationlistStatusid')"></div>
                            <div v-html="svgDeclined('publicationlistStatusid')"></div>
                        </div>
                    </div>
                    <div v-if="filesError.publicationlist" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{fileErrorMsg}}</span>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('Publicationlist')" class="form-field">
                    <p>{{ $t('publicationListComment') }}</p>
                    <md-field>
                        <md-input v-model="form2.publicationlistcomment" :placeholder="$t('publicationListComment')" />
                    </md-field>
                </div>
                <div class="form-field">
                    <p></p>
                    <span class="block-title">{{ $t('doctorateInRussia') }}</span>
                </div>
                <div class="form-field">
                    <p>{{ $t('presentSituation') }} <span v-if="requiredHandler('Professional') || requiredHandler('Jobseeker')">*</span></p>
                    <div class="radio-wrap">
                        <md-radio v-model="form2.Étudiant" value="Yes" class="md-primary">{{ $t('presentSituationStudent') }}</md-radio>
                        <md-radio v-model="form2.Étudiant" value="No" class="md-primary">{{ $t('presentSituationProfessional') }}</md-radio>
                        <md-radio v-model="form2.Étudiant" value="And" class="md-primary">{{ $t('presentSituationJobSeeker') }}</md-radio>
                    </div>
                </div>
                <div v-if="form2.Étudiant === 'Yes'"  class="form-field">
                    <p>{{ $t('institutionCity') }} <span v-if="requiredHandler('InstitutioncityId')">*</span></p>
                    <select name="" class="select-field" v-model="form2.institutioncityid" :required="requiredHandler('InstitutioncityId')">
                        <option
                            v-for="(city,i) in institutionCities"
                            :selected="form2.institutioncityid === city.id"
                            :key="i"
                            :value="city.id"
                        >
                            {{ city.name }}
                        </option>
                    </select>
                </div>
                    <div v-for="(city,i) in institutionCities" :key="'s' + i">
                        <div v-if="form2.institutioncityid === city.id && city.name === 'Autre' && form2.Étudiant === 'Yes'" class="form-field">
                            <p>{{ $t('Lastdegreeinstitut') }} *</p>
                            <md-field>
                                <md-input
                                    v-model="form2.presentcourseinstitutioncity"
                                    :placeholder="$t('Lastdegreeinstitut')"
                                    :required="requiredHandler('Lastdegreespeciality')"
                                ></md-input>
                            </md-field>
                        </div>
                    </div>
                <div v-if="form2.Étudiant === 'Yes'" class="form-field">
                    <p>{{ $t('institutionName') }} <span v-if="requiredHandler('InstitutionnameId')">*</span></p>
                    <select name="" class="select-field" v-model="form2.institutionnameid" :required="requiredHandler('InstitutionnameId')">
                        <option
                            v-for="(institution,i) in institutionNames"
                            :selected="form2.institutionnameid === institution.id"
                            :key="i"
                            :value="institution.id"
                        >
                            {{ institution.name }}
                        </option>
                    </select>
                </div>
                    <div v-for="(institution,i) in institutionNames" :key="'a' + i">
                        <div v-if="form2.institutionnameid === institution.id && institution.name === 'Autre' && form2.Étudiant === 'Yes'" class="form-field">
                            <p>{{ $t('Lastdegreeinstitut1') }} *</p>
                            <md-field>
                                <md-input
                                    v-model="form2.presentcourseinstitution"
                                    :placeholder="$t('Lastdegreeinstitut1')"
                                    :required="requiredHandler('Lastdegreespeciality')"
                                ></md-input>
                            </md-field>
                        </div>
                    </div>
                <div v-if="scholarshipTypeHandler('Doctoratethesisdirectorname')" class="form-field">
                    <p><span v-if="parseInt($route.params.id) === 3"></span> {{ $t('doctorateThesisDirectorName') }} <span v-if="requiredHandler('Doctoratethesisdirectorname')">*</span></p>
                    <md-field>
                        <md-input v-model="form2.doctoratethesisdirectorname" :placeholder="$t('doctorateThesisDirectorName')" :required="requiredHandler('Doctoratethesisdirectorname')"></md-input>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('Doctoratethesisdirectoremail')" class="form-field">
                    <p><span v-if="parseInt($route.params.id) === 3"></span> {{ $t('doctorateThesisDirectorEmail') }} <span v-if="requiredHandler('Doctoratethesisdirectoremail')">*</span></p>
                    <md-field>
                        <md-input type="email" v-model="form2.doctoratethesisdirectoremail" :placeholder="$t('doctorateThesisDirectorEmail')" :required="requiredHandler('Doctoratethesisdirectoremail')"></md-input>
                    </md-field>
                </div>
                <div v-if="form2.Étudiant === 'Yes'" class="form-field">
                  <p>{{ $t('presentCourseLevel') }} <span v-if="requiredHandler('PresentcourselevelId')">*</span></p>
                  <select name="" class="select-field" v-model="form2.presentcourselevelid" :required="requiredHandler('PresentcourselevelId')">
                    <option
                        v-for="(level,i) in presentCourseLevels"
                        :selected="form2.presentcourselevelid === level.id"
                        :key="i"
                        :value="level.id"
                    >
                      {{ level.name }}
                    </option>
                  </select>
                </div>
              <div v-if="form2.Étudiant === 'Yes'" class="form-field">
                <p>{{ $t('presentCourseYear') }} <span v-if="requiredHandler('PresentcourseyearId')">*</span></p>
                <select name="" class="select-field" v-model="form2.presentcourseyearid" :required="requiredHandler('PresentcourseyearId')">
                  <option
                      v-for="(year,i) in presentCourseYears"
                      :selected="form2.presentcourseyearid === year.id"
                      :key="i"
                      :value="year.id"
                  >
                    {{ year.name }}
                  </option>
                </select>
              </div>
                <div v-if="scholarshipTypeHandler('Doctoratescholarship') && form2.Étudiant === 'Yes'" class="form-field">
                    <p>{{ $t('doctorateScholarship') }} <span v-if="requiredHandler('Doctoratescholarship')">*</span></p>
                    <div class="radio-wrap tooltip_icon">
                        <md-radio v-model="form2.doctoratescholarship" value="Yes" class="md-primary">{{ $t('yes') }}</md-radio>
                        <md-radio v-model="form2.doctoratescholarship" value="No" class="md-primary">{{ $t('no') }}</md-radio>
                        <div class="tooltip-wrap">
                            <img src="@/assets/images/info.svg" alt="info" @click="formerScholarshipActive = !formerScholarshipActive" />
                            <md-tooltip :md-active.sync="formerScholarshipActive" md-direction="right">
                                <p v-html="formerScholarshipTooltipMsg"></p>
                            </md-tooltip>
                        </div>
                    </div>
                </div>
                <div v-if="form2.doctoratescholarship === 'Yes' && form2.Étudiant === 'Yes'" class="form-field">
                  <p>{{ $t('formerScholarship') }}</p>
                  <md-field>
                    <md-input v-model="form2.formerscholarship" :placeholder="$t('formerScholarship')"></md-input>
                  </md-field>
                </div>
                <div v-if="form2.Étudiant === 'Yes'" class="form-field">
                    <p>{{ $t('presentCourseField') }} <span v-if="requiredHandler('PresentcoursefieldId')">*</span></p>
                    <select name="" class="select-field" v-model="form2.presentcoursefieldid" :required="requiredHandler('PresentcoursefieldId')">
                        <option
                            v-for="(field,i) in presentCourseFields"
                            :selected="form2.presentcoursefieldid === field.id"
                            :key="i"
                            :value="field.id"
                        >
                            {{ field.name }}
                        </option>
                    </select>
                </div>
                <div v-if="scholarshipTypeHandler('Presentcoursespeciality')" class="form-field">
                    <p>{{ $t('presentCourseSpeciality') }} <span v-if="requiredHandler('Presentcoursespeciality')">*</span></p>
                    <md-field>
                        <md-input v-model="form2.presentcoursespeciality" :placeholder="$t('presentCourseSpeciality')" :required="requiredHandler('Presentcoursespeciality')"></md-input>
                    </md-field>
                </div>
                <div v-if="form2.Étudiant === 'Yes'">
                    <div class="form-field">
                        <p>{{ $t('presentCourseLastTranscript') }} <span v-if="requiredHandler('Presentcourselasttranscript')">*</span></p>
                        <md-field :class="{'uploaded': form2.presentcourselasttranscript}">
                            <md-file
                                @change="changeHandler('presentcourselasttranscript', 'presentcourselasttranscriptStatusid')"
                                :placeholder="$t('presentCourseLastTranscript')"
                                :required="requiredFile('Presentcourselasttranscript', 'presentcourselasttranscript')"
                                :value="files.presentcourselasttranscript"
                                accept="application/pdf"
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div v-html="svgApproved('presentcourselasttranscriptStatusid')"></div>
                            <div v-html="svgDeclined('presentcourselasttranscriptStatusid')"></div>
                        </div>
                    </div>
                    <div v-if="filesError.presentcourselasttranscript" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{fileErrorMsg}}</span>
                    </div>
                </div>
                <div v-if="form2.Étudiant === 'Yes'"  class="form-field">
                    <p>{{ $t('presentCourseLastTranscriptComment') }}</p>
                    <md-field>
                        <md-input v-model="form2.presentcourselasttranscriptcomment" :placeholder="$t('presentCourseLastTranscriptComment')" />
                    </md-field>
                </div>
                <div v-if="form2.Étudiant === 'No'" class="form-field">
                    <p>{{ $t('professionJobName') }} <span v-if="requiredHandler('Professionjobname')">*</span></p>
                    <md-field>
                        <md-input v-model="form2.professionjobname" :placeholder="$t('professionJobName')" :required="requiredHandler('Professionjobname')"></md-input>
                    </md-field>
                </div>
                <div v-if="form2.Étudiant === 'No'" class="form-field">
                    <p>{{ $t('professionDateOfHiring') }} <span v-if="requiredHandler('Professiondateofhiring')">*</span></p>
                    <md-datepicker id="professionDateOfHiring" v-model="form2.professiondateofhiring" class="graduationDate" />
                </div>
                <div v-if="form2.Étudiant === 'No'" class="form-field">
                    <p>{{ $t('professionEmployerName') }} <span v-if="requiredHandler('Professionemployername')">*</span></p>
                    <md-field>
                        <md-input v-model="form2.professionemployername" :placeholder="$t('professionEmployerName')" :required="requiredHandler('Professionemployername')"></md-input>
                    </md-field>
                </div>
                <div v-if="form2.Étudiant === 'No'" class="form-field">
                    <p>{{ $t('professionEmployerAddress') }} <span v-if="requiredHandler('Professionemployeraddress')">*</span></p>
                    <md-field>
                        <md-input v-model="form2.professionemployeraddress" :placeholder="$t('professionEmployerAddress')" :required="requiredHandler('Professionemployeraddress')"></md-input>
                    </md-field>
                </div>
                <div v-if="form2.Étudiant === 'Yes'">
                    <div class="form-field">
                        <p>{{ $t('studentCardOrJobPermit') }} <span v-if="requiredHandler('Studentcardorjobpermit')">*</span></p>
                        <md-field :class="{'uploaded': form2.studentcardorjobpermit}">
                            <md-file
                                @change="changeHandler('studentcardorjobpermit', 'studentcardorjobpermitStatusid')"
                                :placeholder="$t('studentCardOrJobPermit')"
                                :required="requiredFile('Studentcardorjobpermit', 'studentcardorjobpermit')"
                                :value="files.studentcardorjobpermit"
                                accept="application/pdf"
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div v-html="svgApproved('studentcardorjobpermitStatusid')"></div>
                            <div v-html="svgDeclined('studentcardorjobpermitStatusid')"></div>
                        </div>
                    </div>
                    <div v-if="filesError.studentcardorjobpermit" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{fileErrorMsg}}</span>
                    </div>
                </div>
                <div v-if="form2.Étudiant === 'Yes'" class="form-field">
                    <p>{{ $t('studentCardOrJobPermitComment') }}</p>
                    <md-field>
                        <md-input v-model="form2.studentcardorjobpermitcomment" :placeholder="$t('studentCardOrJobPermitComment')"/>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('FrenchlanguagelevelId') " class="form-field">
                    <p>{{ $t('frenchLanguageLevel') }} <span v-if="requiredHandler('FrenchlanguagelevelId')">*</span></p>
                    <select name="" class="select-field" v-model="form2.frenchlanguagelevelid" :required="requiredHandler('FrenchlanguagelevelId')">
                        <option
                            v-for="(languageLevel,i) in frenchLanguageLevels"
                            :selected="form2.frenchlanguagelevelid === languageLevel.id"
                            :key="i"
                            :value="languageLevel.id"
                        >
                            {{ languageLevel.name }}
                        </option>
                    </select>
                </div>
                <div v-if="scholarshipTypeHandler('Frenchlanguagecertificate') ">
                    <div class="form-field">
                        <p>{{ $t('frenchLanguageCertificate') }} <span v-if="requiredHandler('Frenchlanguagecertificate')">*</span></p>
                        <md-field :class="{'uploaded': form2.frenchlanguagecertificate}">
                            <md-file
                                @change="changeHandler('frenchlanguagecertificate', 'frenchlanguagecertificateStatusid')"
                                :placeholder="$t('frenchLanguageCertificate')"
                                :required="requiredFile('Frenchlanguagecertificate', 'frenchlanguagecertificate')"
                                :value="files.frenchlanguagecertificate"
                                accept="application/pdf"
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div v-html="svgApproved('frenchlanguagecertificateStatusid')"></div>
                            <div v-html="svgDeclined('frenchlanguagecertificateStatusid')"></div>
                        </div>
                    </div>
                    <div v-if="filesError.frenchlanguagecertificate" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{fileErrorMsg}}</span>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('Frenchlanguagecertificate') " class="form-field">
                    <p>{{ $t('frenchLanguageCertificateComment') }}</p>
                    <md-field>
                        <md-input v-model="form2.frenchlanguagecertificatecomment" :placeholder="$t('frenchLanguageCertificateComment')"/>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('OtherforeignlanguageId')" class="form-field">
                    <p>{{ $t('otherForeignLanguage') }} <span v-if="requiredHandler('OtherforeignlanguageId')">*</span></p>
                    <select name="" class="select-field" v-model="form2.otherforeignlanguageid" :required="requiredHandler('OtherforeignlanguageId')">
                        <option
                            v-for="(language,i) in otherForeignLanguages"
                            :selected="form2.otherforeignlanguageid === language.id"
                            :key="i"
                            :value="language.id"
                        >
                            {{ language.name }}
                        </option>
                    </select>
                </div>
                <div v-if="scholarshipTypeHandler('Otherforeignlanguagecertificate') ">
                    <div class="form-field">
                        <p>{{ $t('otherForeignLanguageCertificate') }} <span v-if="requiredHandler('Otherforeignlanguagecertificate')">*</span></p>
                        <md-field :class="{'uploaded': form2.otherforeignlanguagecertificate}">
                            <md-file
                                @change="changeHandler('otherforeignlanguagecertificate', 'otherforeignlanguagecertificateStatusid')"
                                :placeholder="$t('otherForeignLanguageCertificate')"
                                :required="requiredFile('Otherforeignlanguagecertificate', 'otherforeignlanguagecertificate')"
                                :value="files.otherforeignlanguagecertificate"
                                accept="application/pdf"
                            ></md-file>
                        </md-field>
                        <div class="document-status">
                            <div v-html="svgApproved('otherforeignlanguagecertificateStatusid')"></div>
                            <div v-html="svgDeclined('otherforeignlanguagecertificateStatusid')"></div>
                        </div>
                    </div>
                    <div v-if="filesError.otherforeignlanguagecertificate" class="form-field">
                        <p></p>
                        <span class="file-error-msg">{{fileErrorMsg}}</span>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('Otherforeignlanguagecertificate') " class="form-field">
                    <p>{{ $t('otherForeignLanguageCertificateComment') }}</p>
                    <md-field>
                        <md-input v-model="form2.otherforeignlanguagecertificatecomment" :placeholder="$t('otherForeignLanguageCertificateComment')"/>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('Travelledinfrance') " class="form-field">
                    <p>{{ $t('travelledInFrance') }} <span v-if="requiredHandler('Travelledinfrance')">*</span></p>
                    <div class="radio-wrap">
                        <md-radio v-model="form2.travelledinfrance" value="Yes" class="md-primary">{{ $t('yes') }}</md-radio>
                        <md-radio v-model="form2.travelledinfrance" value="No" class="md-primary">{{ $t('no') }}</md-radio>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('Travelledinfrancecomment') && form2.travelledinfrance === 'Yes'" class="form-field form-field_textarea">
                    <p>{{ $t('travelledInFranceComment') }} <span v-if="requiredHandler('Travelledinfrancecomment')">*</span></p>
                    <md-field>
                        <md-textarea v-model="form2.travelledinfrancecomment" placeholder="" :required="requiredHandler('Travelledinfrancecomment')"></md-textarea>
                    </md-field>
                </div>
                <div v-if="scholarshipTypeHandler('FormerFrenchScholarship')" class="form-field">
                    <p>{{ $t('formerFrenchScholarship') }} <span v-if="requiredHandler('FormerFrenchScholarship')">*</span></p>
                    <div class="radio-wrap">
                        <md-radio v-model="form2.formerFrenchScholarship" value="Yes" class="md-primary">{{ $t('yes') }}</md-radio>
                        <md-radio v-model="form2.formerFrenchScholarship" value="No" class="md-primary">{{ $t('no') }}</md-radio>
                    </div>
                </div>
                <div v-if="scholarshipTypeHandler('FormerFrenchScholarshipTypeId') && form2.formerFrenchScholarship === 'Yes'" class="form-field">
                    <p>{{ $t('scholarshipType') }} <span v-if="requiredHandler('FormerFrenchScholarshipTypeId')">*</span></p>
                    <select name="" class="select-field" v-model="form2.formerFrenchScholarshipTypeid" :required="requiredHandler('FormerFrenchScholarshipTypeId')">
                        <option
                            v-for="(type,i) in formerFrenchScholarshipType"
                            :selected="form2.formerFrenchScholarshipTypeid === type.id"
                            :key="i"
                            :value="type.id"
                        >
                            {{ type.name }}
                        </option>
                    </select>
                </div>
                <div v-if="scholarshipTypeHandler('FormerFrenchScholarshipYear') && form2.formerFrenchScholarship === 'Yes'" class="form-field">
                    <p>{{ $t('year') }} <span v-if="requiredHandler('FormerFrenchScholarshipYear')">*</span></p>
                    <select name="" class="select-field" v-model="form2.formerFrenchScholarshipYear" :required="requiredHandler('FormerFrenchScholarshipYear')">
                        <option
                            v-for="year in years"
                            :selected="form2.formerFrenchScholarshipYear === year"
                            :key="year"
                            :value="year"
                        >
                            {{ year }}
                        </option>
                    </select>
                </div>
                <div  class="form-field">
                    <p>{{ $t('profileComment') }}</p>
                    <md-field>
                        <md-input v-model="form2.comment" :placeholder="$t('profileComment')"></md-input>
                    </md-field>
                </div>
                <div class="form-field applications-error">
                    <p></p>
                    <Errors
                            :errors="errors"
                            class="applications"
                    />
                    <Messages :messages="messages"></Messages>
                </div>
                <div class="form-field">
                    <p></p>
                    <md-button class="md-raised cancel" @click="showModal">
                        <span>Annuler</span>
                    </md-button>
                    <md-button :class="{'disabled': disabled}" :disabled="disabled" class="next" type="submit">
                        <span>Sauvegarder</span>
                    </md-button>
                </div>
            </form>
        </div>
        <CloseModal
            v-if="showCloseModal"
            :application="true"
            @cancel="cancel"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters } from "vuex";
import {errors} from "@/mixins/errors";
import {messages} from "@/mixins/messages";
import Errors from "@/components/Errors";
import Messages from "@/components/Messages";
import Breadcrumbs from '@/components/breadcrumbs';
import CloseModal from '@/components/cancel-modal';

export default {
        name: 'application-profile',

        mixins: [
            errors,
            messages,
        ],

        components: {
            Errors,
            Messages,
            Breadcrumbs,
            CloseModal
        },
        data: () => ({
            showCloseModal: false,
            disabled: false,
            index: 1,
            edit: false,
            files: {},
            presentCourseInstitutionsSec: [],
            filesError: {},
            fileErrorMsg: 'Merci de convertir votre fichier au format pdf avant de le télécharger',
            radio: 'student',
            years: [2010],
            form2: {
                studentId: null,
                resume: '',
                resumeStatusId: 1,
                resumecomment: '',
                lastdegreedateofgraduation: null,
                lastdegreelevelid: '',
                lastdegreefieldid: '',
                lastdegreespeciality: '',
                lastdegreeinstitutioncityid: '',
                lastdegreeredid: 2,
                lastdegreeinstitutionid: '',
                lastdegreecertifiedcopy: '',
                lastdegreecertifiedcopyStatusid: 1,
                lastdegreecertifiedcopycomment: '',
                lastdegreetranscript: '',
                lastdegreetranscriptStatusid: 1,
                lastdegreetranscriptcomment: '',
                publicationlist: '',
                publicationlistStatusid: 1,
                publicationlistcomment: '',
                institutionnameid: '',
                institutioncityid: '',
                doctoratethesisdirectorname: '',
                doctoratethesisdirectoremail: '',
                doctoratescholarship: 'No',
                presentcourseinstitutioncityid: '',
                presentcourselevelid: '',
                presentcourseyearid: '',
                presentcoursefieldid: '',
                presentcoursespeciality: '',
                presentcourseinstitutionid: '',
                presentcourselasttranscript: '',
                presentcourselasttranscriptStatusid: 1,
                presentcourselasttranscriptcomment: '',
                student: false,
                professional: false,
                professionjobname: '',
                professiondateofhiring: null,
                professionemployername: '',
                professionemployeraddress: '',
                jobseeker: false,
                studentcardorjobpermit: '',
                studentcardorjobpermitStatusid: 1,
                studentcardorjobpermitcomment: '',
                formerscholarship: '',
                frenchlanguagelevelid: '',
                frenchlanguagecertificate: '',
                frenchlanguagecertificateStatusid: 1,
                frenchlanguagecertificatecomment: '',
                otherforeignlanguageid: '',
                otherforeignlanguagecertificate: '',
                otherforeignlanguagecertificateStatusid: 1,
                otherforeignlanguagecertificatecomment: '',
                travelledinfrance: 'No',
                travelledinfrancecomment: '',
                formerFrenchScholarship: 'No',
                formerFrenchScholarshipTypeid: '',
                formerFrenchScholarshipYear: '',
                comment: '',
                studyEstablishment: '',
                Étudiant:"No",
                lastdegreeinstitution:"",
                lastdegreeinstitutioncity:"",
                presentcourseinstitutioncity:"",
                presentcourseinstitution:""
            },
            items: [
                {
                    text: 'home',
                    href: '/'
                },
                {
                    text: 'profile',
                    href: '#',
                }
            ],
            lastDegreeGraduationTooltipMsg: 'Si vous êtes doctorant (kandidat nauk), merci <br /> d’indiquer la date prévue d’obtention de votre <br /> diplôme',
            formerScholarshipTooltipMsg: 'Bourse délivrée par un Etat autre que la France',
            lastDegreeGraduationTooltipActive: false,
            formerScholarshipActive: false,
            lastDegreeRedTooltipActive: false,
            lastDegreeRedTooltipMsg: 'Si vous êtes actuellement en dernière année <br /> de licence et que vous n’avez pas encore reçu <br /> votre diplôme, merci de cocher la case <br /> « en cours »',
        }),
        computed: {
            ...mapGetters(['simpleLists']),
            ...mapGetters('applications', ['profileMetaInfo', 'profileData', 'currentScholarshipTypeId']),

            formerFrenchScholarshipType() {
                return this.simpleLists?.formerFrenchScholarshipType
            },
            scholarshipTypes() {
                return this.simpleLists?.scholarshipType
            },
            seniorOrJunior() {
                let name = 'Metchnikov senior';
                const currentYear = new Date().getFullYear();
                if(this.form2.lastdegreedateofgraduation) {
                    const graduation = new Date(this.form2.lastdegreedateofgraduation);
                    const diff = currentYear - parseInt(graduation);
                    if(diff < 5) {
                        name = "Metchnikov junior"
                    }
                }
                return name;
            },
            scholarshipType() {
                const id = this.$route.params.id || this.currentScholarshipTypeId;
                const currentType = this.scholarshipTypes?.filter(type => type.id == id);
                return currentType ? currentType[0]?.name : ''
            },
            lastDegreeFields() {
                return this.simpleLists?.lastdegreefield
            },
            lastDegreeLevels() {
                return this.simpleLists?.lastdegreelevel
            },
            institutionCities() {
              return this.simpleLists?.institutioncity
            },
            institutionNames() {
                let data = [];
                let obj = {
                    id: 201,
                    lastdegreeinstitutioncityId: 84,
                    name: "Autre",
                    orderBy: 201,
                }
                if(this.form2.institutioncityid) {
                    this.simpleLists?.institutionname.forEach(item => {
                        if(item.institutioncityId === this.form2.institutioncityid) {
                            data.push(item)
                        }
                    })
                    for (let ell of data) {
                        if (ell.name !== "Autre") {
                            data.push(obj)
                        }
                        break;
                    }
                } else {
                    data = this.simpleLists?.institutionname
                }
                return data?.sort((a,b) => {
                    return a.name?.trim().localeCompare(b.name?.trim())
                })
            },
            presentCourseLevels() {
              return this.simpleLists?.presentcourselevel
            },
            presentCourseYears() {
              return this.simpleLists?.presentcourseyear
            },
            presentCourseFields() {
              return this.simpleLists?.presentcoursefield
            },
            presentCourseInstitutions() {
              return this.simpleLists?.presentcourseinstitution
            },
            frenchLanguageLevels() {
              return this.simpleLists?.frenchlanguagelevel
            },
            otherForeignLanguages() {
              return this.simpleLists?.otherforeignlanguage
            },
            showLastDegreeGraduationTooltip() {
                return this.scholarshipType === 'Metchnikov' ||
                    this.scholarshipType ===  "Ostrogradski" ||
                    this.scholarshipType ===  "Vernadski"
            },
            isMaster() {
                return this.scholarshipType === 'Master'
            },
            isVernadski() {
                return this.scholarshipType === 'Vernadski'
            },
            isOstrogradski() {
                return this.scholarshipType === 'Ostrogradski'
            },
            lastDegreeRedList() {
                if(!this.isMaster) {
                    return this.simpleLists?.lastdegreered.filter(item => item.id !== 3)
                }
                return this.simpleLists?.lastdegreered
            },
            lastDegreeInstitutionCities() {
                return this.simpleLists?.lastdegreeinstitutioncity
            },
            lastDegreeInstitution() {
                let data = [];
                let obj = {
                    id: 201,
                    lastdegreeinstitutioncityId: 84,
                    name: "Autre",
                    orderBy: 201,
                }

                if(this.form2.lastdegreeinstitutioncityid) {
                    this.simpleLists?.lastdegreeinstitution.forEach(item => {
                        if(item.lastdegreeinstitutioncityId === this.form2.lastdegreeinstitutioncityid.id) {
                            data.push(item)
                        }
                    })
                   for (let ell of data) {
                       if (ell.name !== "Autre") {
                           data.push(obj)
                       }
                       break;
                   }
                } else {
                    data = this.simpleLists?.lastdegreeinstitution
                }
                return data?.sort((a,b) => {
                    return a.name?.trim().localeCompare(b.name?.trim())
                })
            },
            presentCourseInstitutionCities() {
                return this.simpleLists?.presentcourseinstitutioncity
            }
        },
        watch: {
            lastDegreeInstitutionCities(val) {
                if (val) {
                    this.form2.lastdegreeinstitutioncityid = this.lastDegreeInstitutionCities.find(city => city.id === this.form2.lastdegreeinstitutioncityid)
                }
            },
            'form2.presentcourseinstitutioncityid' (val) {
              if (val && this.presentCourseInstitutions) {
                this.presentCourseInstitutionsSec = this.presentCourseInstitutions.filter(item => {
                  if (item.presentcourseinstitutioncityId === val.id) {
                    return item
                  }
                })
              }
            },
            presentCourseInstitutionCities(val) {
                if (val) {
                    this.form2.presentcourseinstitutioncityid = this.presentCourseInstitutionCities.find(city => city.id === this.form2.presentcourseinstitutioncityid)
                }
            },
            'form2.travelledinfrance'(val) {
                if(val === 'No') {
                    this.form2.travelledinfrancecomment = '';
                }
            },
            'form2.formerFrenchScholarship'(val) {
                if(val === 'No') {
                    this.form2.formerFrenchScholarshipTypeid = '';
                    this.form2.formerFrenchScholarshipYear = ''
                }
            },
            $route() {
                this.clearErrors()
            },
            'form2.lastdegreespeciality'(newV, oldV) {
                this.watchHandler(newV, oldV, 'lastdegreespeciality')
            },
            'form2.lastdegreeinstitution'(newV, oldV) {
                this.watchHandler(newV, oldV, 'lastdegreeinstitution')
            },
            'form2.doctoratethesisdirectorname'(newV, oldV) {
                this.watchHandler(newV, oldV, 'doctoratethesisdirectorname')
            },
            'form2.doctoratethesisdirectoremail'(newV, oldV) {
                this.watchHandler(newV, oldV, 'doctoratethesisdirectoremail')
            },
            'form2.presentcoursespeciality'(newV, oldV) {
                this.watchHandler(newV, oldV, 'presentcoursespeciality')
            },
            'form2.professionjobname'(newV, oldV) {
                this.watchHandler(newV, oldV, 'professionjobname')
            },
            'form2.professionemployername'(newV, oldV) {
                this.watchHandler(newV, oldV, 'professionemployername')
            },
            'form2.professionemployeraddress'(newV, oldV) {
                this.watchHandler(newV, oldV, 'professionemployeraddress')
            },
            'form2.formerscholarship'(newV, oldV) {
                this.watchHandler(newV, oldV, 'formerscholarship')
            }
        },
        async mounted() {
            this.getYears();
            await this.getAccountProfileFieldsMetaInfo();
            if(!this.$route.name.includes('student')) {
                await this.formatProfileData()
            }
            if (this.$route.params.studentId && this.$route.params.studentId != 0) {
               this.form2.studentId =this.$route.params.studentId
                await this.formatProfileData()
            }
            const professionDateOfHiring = document.querySelectorAll('#professionDateOfHiring')[0]?.childNodes[2];

            if (professionDateOfHiring) {
              professionDateOfHiring.setAttribute('required', true)
            }
        },
        methods: {
            ...mapActions('auth', ['logout']),
            ...mapActions('applications', ['getAccountProfileFieldsMetaInfo', 'getProfileData']),
           async formatProfileData() {
                const data = await this.getProfileData();
                if (data.success) {
                    for(let field in this.form2) {
                        if(data[field] !== "null") {
                            this.form2[field] = data[field]
                        }
                    }
                    this.form2.doctoratescholarship = data.doctoratescholarship ? 'Yes' : 'No';
                    this.form2.travelledinfrance = data.travelledinfrance ? 'Yes' : 'No';
                    this.form2.formerFrenchScholarship = data.formerFrenchScholarship ? 'Yes' : 'No';
                    this.form2.lastdegreedateofgraduation = new Date(this.form2.lastdegreedateofgraduation) ;
                    this.form2.professiondateofhiring = data.professiondateofhiring ? this.$moment(data.professiondateofhiring).format("DD-MM-YYYY") : null;
                    this.form2.lastdegreeinstitution = data.lastdegreeinstitution;
                    this.form2.lastdegreeinstitutioncity = data.lastdegreeinstitutioncity;
                    this.form2.presentcourseinstitutioncity = data.presentcourseinstitutioncity;
                    this.form2.presentcourseinstitution = data.presentcourseinstitution;
                    if (data.student) { this.radio = 'student'; this.form2.Étudiant = "Yes" }
                    if (data.professional) { this.radio = 'professional'; this.form2.Étudiant = "No"}
                    if (data.jobseeker) { this.radio = 'jobseeker'; this.form2.Étudiant = "And" }
                    this.form2.lastdegreeinstitutioncityid = this.lastDegreeInstitutionCities.find(city => city.id === this.form2.lastdegreeinstitutioncityid)
                    this.form2.presentcourseinstitutioncityid = this.presentCourseInstitutionCities?.find(city => city.id === this.form2.presentcourseinstitutioncityid)
                    this.formattedFiles();
                }
            },
            getYears() {
                let currentYear = new Date().getFullYear();

                let i = 0;
                while (this.years[i] < currentYear - 1) {
                    this.years.push(this.years[i] + 1);
                    i++
                }
            },
            formattedFiles() {
                this.files.resume = this.form2.resume?.replace('Files/', ' ');
                this.files.lastdegreecertifiedcopy = this.form2.lastdegreecertifiedcopy?.replace('Files/', ' ');
                this.files.lastdegreetranscript = this.form2.lastdegreetranscript?.replace('Files/', ' ');
                this.files.publicationlist = this.form2.publicationlist?.replace('Files/', ' ');
                this.files.presentcourselasttranscript = this.form2.presentcourselasttranscript?.replace('Files/', ' ');
                this.files.studentcardorjobpermit = this.form2.studentcardorjobpermit?.replace('Files/', ' ');
                this.files.frenchlanguagecertificate = this.form2.frenchlanguagecertificate?.replace('Files/', ' ');
                this.files.otherforeignlanguagecertificate = this.form2.otherforeignlanguagecertificate?.replace('Files/', ' ')
            },
            showModal() {
              this.showCloseModal = true
            },
            async cancel() {
                if(this.currentScholarshipTypeId) {
                    this.$router.push('/application')
                } else {
                    const success = await this.logout()
                    if (success) {
                        this.$router.push('/')
                    }
                }
            },
            selectDocumentStatus(field, value) {
                this.form2[field] = value
            },
            svgApproved(field) {
                return (this.form2[field] === 2) ? `<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.879 0.578766C20.5132 1.07185 20.6277 1.98568 20.1346 2.61988L8.82556 17.1653C8.5737 17.4892 8.19676 17.6918 7.78761 17.7229C7.37847 17.7541 6.97521 17.6109 6.67725 17.3288L0.531697 11.5106C-0.0516634 10.9583 -0.0768557 10.0377 0.475429 9.45435C1.02771 8.87099 1.94833 8.8458 2.53169 9.39808L7.51302 14.114L17.8379 0.834273C18.331 0.20008 19.2449 0.0856857 19.879 0.578766Z" fill="#0045FF"/>
                </svg>`
                    :
                    `<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.879 0.578766C20.5132 1.07185 20.6277 1.98568 20.1346 2.61988L8.82556 17.1653C8.5737 17.4892 8.19676 17.6918 7.78761 17.7229C7.37847 17.7541 6.97521 17.6109 6.67725 17.3288L0.531697 11.5106C-0.0516634 10.9583 -0.0768557 10.0377 0.475429 9.45435C1.02771 8.87099 1.94833 8.8458 2.53169 9.39808L7.51302 14.114L17.8379 0.834273C18.331 0.20008 19.2449 0.0856857 19.879 0.578766Z" fill="#CDD0D9"/>
                </svg>`
            },
            svgDeclined(field) {
                return (this.form2[field] === 3) ? `<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9951 17.5362C16.4921 18.0332 17.298 18.0332 17.795 17.5362C18.292 17.0392 18.292 16.2333 17.795 15.7363L11.0586 8.99992L17.795 2.26352C18.292 1.76648 18.292 0.960639 17.795 0.463608C17.298 -0.0334225 16.4921 -0.0334225 15.9951 0.463608L9.25871 7.20002L2.52229 0.463594C2.02526 -0.0334377 1.21941 -0.0334377 0.722384 0.463594C0.225352 0.960624 0.225352 1.76647 0.722384 2.26351L7.45881 8.99992L0.722382 15.7363C0.225352 16.2333 0.225352 17.0392 0.722382 17.5362C1.21941 18.0333 2.02526 18.0333 2.52229 17.5362L9.25871 10.7998L15.9951 17.5362Z" fill="#FF4646"/>
                </svg>`
                    :
                `<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9951 17.5362C16.4921 18.0332 17.298 18.0332 17.795 17.5362C18.292 17.0392 18.292 16.2333 17.795 15.7363L11.0586 8.99992L17.795 2.26352C18.292 1.76648 18.292 0.960639 17.795 0.463608C17.298 -0.0334225 16.4921 -0.0334225 15.9951 0.463608L9.25871 7.20002L2.52229 0.463594C2.02526 -0.0334377 1.21941 -0.0334377 0.722384 0.463594C0.225352 0.960624 0.225352 1.76647 0.722384 2.26351L7.45881 8.99992L0.722382 15.7363C0.225352 16.2333 0.225352 17.0392 0.722382 17.5362C1.21941 18.0333 2.02526 18.0333 2.52229 17.5362L9.25871 10.7998L15.9951 17.5362Z" fill="#CDD0D9"/>
                </svg>`
            },
            watchHandler(newV, oldV, field) {
                const regex = /^([\s!@#$%^&*()_+=-`~\\\\[{}|';:/.,?><0-9A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/;
                if(newV && newV.match(regex) === null) {
                    this.$nextTick(() => {
                        this.form2[field] = oldV;
                    })
                }
            },
            scholarshipTypeHandler(field) {
                return this.profileMetaInfo?.[field]?.[this.scholarshipType]
            },
            requiredHandler(field) {
                return this.profileMetaInfo?.[field]?.Mandatory
            },
            requiredFile(field, key) {
                if(this.form2[key]) {
                    return;
                }
                return this.profileMetaInfo?.[field]?.Mandatory
            },
            changeHandler(field, status) {
                if (event.target.files[0].type !== 'application/pdf') {
                    this.filesError[field] = true;
                    this.form2[status] = '';
                    this.form2[field] = '';
                    this.files[field] = '';
                    return;
                }
                this.form2[status] = 1;
                this.form2[field] = event.target.files[0];
                this.files[field] = event.target.files[0].name;
                this.filesError[field] = false;
            },
            save() {
//                this.clearErrors();
//                this.clearMessages();
//                this.disabled = true
//                this.index++
//                if (this.form2.Étudiant === 'Yes') {
//                    this.form2.student = 'Yes';
//                    this.form2.professional = false;
//                    this.form2.jobseeker = false;
//                    this.form2.professionjobname = '';
//                    this.form2.professiondateofhiring = '';
//                    this.form2.professionemployername = '';
//                    this.form2.professionemployeraddress = '';
//                } else if(this.form2.Étudiant === 'No') {
//                    this.form2.professional = 'Yes';
//                    this.form2.student = false;
//                    this.form2.jobseeker = false;
//                } else if(this.form2.Étudiant === 'And') {
//                    this.form2.jobseeker = 'Yes';
//                    this.form2.student = false;
//                    this.form2.professional = false;
//                    this.form2.professionjobname = '';
//                    this.form2.professiondateofhiring = '';
//                    this.form2.professionemployername = '';
//                    this.form2.professionemployeraddress = '';
//                }
//                this.form2.lastdegreedateofgraduation = this.$moment(this.form2.lastdegreedateofgraduation).format("DD-MM-YYYY");
//                this.form2.professiondateofhiring = this.$moment(this.form2.professiondateofhiring).format("DD-MM-YYYY");
//                this.form2.lastdegreeinstitutioncityid =  this.form2.lastdegreeinstitutioncityid?.id
//                this.form2.presentcourseinstitutioncityid =  this.form2.presentcourseinstitutioncityid?.id
//                const formData = new FormData();
//                for (let field in this.form2) {
//                    formData.append(field, this.form2[field]);
//                }
//                const token = JSON.parse(localStorage.getItem('bgfNewData')).token ? JSON.parse(localStorage.getItem('bgfNewData')).token : localStorage.getItem('bgfCandidateToken')
//                this.$axios
//                    .post(process.env.VUE_APP_API_URL + '/accountprofile/edit', formData , {
//                        headers: {
//                            'Content-Type': 'multipart/form-data',
//                            'Authorization': token
//                        }
//                    })
//                    .then(({data}) => {
//                      this.disabled = false
//                        if (data.success) {
//                          this.formatProfileData()
//                            if (this.$route.params.studentId && this.$route.params.studentId != 0 && this.$route.name === 'student-profile') {
//                                this.$router.push({
//                                    name: 'student-project',
//                                    params: {
//                                        id: this.$route.params.id,
//                                        studentId: this.$route.params.studentId,
//                                        profileId: data.id
//                                    }
//                                })
//                            } else {
//                              this.form2.presentcourseinstitutioncityid = this.presentCourseInstitutionCities?.find(city => city.id === this.form2.presentcourseinstitutioncityid)
//                              this.$notify({
//                                    group: 'success-notify',
//                                    type: 'success',
//                                    title: this.$t('savedMessage'),
//                                    text: '',
//                                });
//                            }
//                        } else {
//                            this.setErrors([data.error]);
//                        }
//                    });
            }
        }
    }
</script>

<style lang="scss">
.applications-form_wrap {
    width: 920px;
    .form-status_wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        form {
            width: 100%;
        }
    }

    .block-title {
        font-size: 24px;
        margin-left: 16px;
    }

    .applications-form_header {
        font-weight: 900;
        font-size: 36px;
        line-height: 52px;
        text-align: left;
        margin: 0 0 35px 30px;
    }

    .md-datepicker {
        align-items: center;

        svg {
            fill: #0045FF !important;
        }
    }
    .md-field.md-theme-default > .md-icon:after {
        display: none;
    }

    .md-field .md-input-action {
        top: 8px!important;
    }

    .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
        border-color: transparent !important;
    }

    .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):after {
        border-color: transparent !important;
    }

    .md-file {
        .md-file-icon {
            width: 80px;
            height: 48px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            margin: 0;
            background-color: #0045FF;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 22px;
                fill: #FFFFFF;
                transform: rotate(45deg);
            }
        }

        .md-input {
            margin: 0 !important;
            height: 48px !important;
        }
    }
}
.md-tooltip {
    color: #000000!important;
    background-color: #FFFFFF!important;
    border: 1px solid #cdd0d9;
    border-radius: 6px;
    padding: 15px;
    width: 400px!important;
    height: auto!important;
    font-size: 16px;
    line-height: 24px;
}
.vs__dropdown-toggle {
    width: 100%;
    height: 100%;
    padding-left: 20px;
}
.vs__actions {
    padding-right: 3px;
}
.vs__clear {
    display: none;
}
.vs__open-indicator {
    fill: black;
    transform: scale(0.65);
}
</style>

<style lang="scss" scoped>
.applications-form_wrap {
    .disabled {
      background-color: grey!important;
    }
    .document-status {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
        div {
            cursor: pointer;
        }
    }
    .form-field {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        p {
            width: 204px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
        }
        .md-field {
            width: 45%;
            max-width: 410px;
            background: #FFFFFF;
            border: 1px solid #CDD0D9;
            box-sizing: border-box;
            border-radius: 4px;
            margin: 0 0 0 16px;
            padding: 0 0 0 24px;

            .md-input {
                height: 48px !important;
            }
            .md-file {
                flex-direction: row-reverse;
            }
        }
        .uploaded {
            background: #DCDCDC;
        }
        .md-field:after, .md-field:before {
            position: unset !important;
        }
        .select-field {
            width: 45%;
            max-width: 410px;
            background: #FFFFFF;
            border: 1px solid #CDD0D9;
            outline: none;
            box-sizing: border-box;
            border-radius: 4px;
            height: 48px;
            padding: 0 0 0 25px;
            margin-left: 16px;

            option {
                height: 48px;
            }
        }
    }
    .file-error-msg {
        color: red;
        margin: -16px 0 0 16px;
    }
    .form-field_textarea {
        align-items: flex-start;
        .md-field {
            padding: 0;
            .md-textarea {
                min-height: 150px;
            }
        }
    }
    .applications-error {
        margin-bottom: 0;
    }
    .cancel {
        width: 130px;
        height: 40px;
        margin-left: 25px;
        background: #FFFFFF;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;
        }
    }
    .next {
        width: 130px;
        height: 40px;
        margin-left: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        border-radius: 6px;
        background-color: #0045FF;
        color: #FFFFFF;

        img {
            margin-left: 13px;
        }
    }
    .radio-wrap {
        margin-left: 16px;
        width: 45%;
        max-width: 410px;
    }
    .tooltip-wrap {
        margin-left: 15px;
    }
    .custom-select {
        border: none!important;
        padding: 0!important;
    }
}
.tooltip_icon {
    display: flex;
    align-items: center;
}
</style>